import { render, staticRenderFns } from "./addUserManage.vue?vue&type=template&id=97b22258"
import script from "./addUserManage.vue?vue&type=script&lang=js"
export * from "./addUserManage.vue?vue&type=script&lang=js"
import style0 from "./addUserManage.vue?vue&type=style&index=0&id=97b22258&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "addUserManage.vue"
export default component.exports