<template>
  <div class="addUserManage">
    <div class="all">
      <div class="addUser-top positionr">
        <span class="span"></span>
        <p class="title">客户类型</p>
        <el-button
          :class="{ active: customerType == '1' }"
          @click="changeName('1')"
          class="btn"
        >
          自然人
        </el-button>
        <el-button
          :class="{ active: customerType == '2' }"
          @click="changeName('2')"
          class="btn"
        >
          公司
        </el-button>
        <el-button
          :class="{ active: customerType == '3' }"
          @click="changeName('3')"
          class="btn"
        >
          公共屋顶
        </el-button>
      </div>
      <!-- 表单 -->
      <div>
        <div v-if="customerType === '1'">
          <el-form
            :model="formInline1"
            ref="customerForm"
            class="demo-form-inline"
            label-width="100px"
            :rules="manageRules"
          >
            <div class="addUser-main positionr">
              <span class="span"></span>
              <p class="title">基本信息</p>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="姓名" prop="customerName">
                    <el-input
                      clearable
                      v-model="formInline1.customerName"
                      placeholder="请输入姓名"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="手机号" prop="mobile" required>
                    <el-input
                      clearable
                      v-model="formInline1.mobile"
                      placeholder="请输入手机号"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="归属公司" required>
                    <el-select
                      style="width: 100%"
                      value-key="organCode"
                      v-model="isFirstObj"
                    >
                      <el-option
                        :label="item.agentName"
                        :value="item"
                        v-for="(item, index) in companyOptions"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="身份证号" prop="certificateNo" required>
                    <el-input
                      @change="setForm1Sex"
                      v-model="formInline1.certificateNo"
                      class="small-height-input"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- line2 -->
                <el-col :span="8">
                  <el-form-item label="订单进度">
                    <el-select
                      class="small-height-input"
                      size="mini"
                      v-model="formInline1.orderSchedule"
                    >
                      <el-option
                        label="待提交审核"
                        value="待提交审核"
                      ></el-option>
                      <el-option
                        label="待资料审核"
                        value="待资料审核"
                      ></el-option>
                      <el-option
                        label="待发货申请"
                        value="待发货申请"
                      ></el-option>
                      <el-option
                        label="待发货调度"
                        value="待发货调度"
                      ></el-option>
                      <el-option
                        label="待安装完工"
                        value="待安装完工"
                      ></el-option>
                      <el-option
                        label="待验收派单"
                        value="待验收派单"
                      ></el-option>
                      <el-option
                        label="待现场验收"
                        value="待现场验收"
                      ></el-option>
                      <el-option
                        label="待消缺整改"
                        value="待消缺整改"
                      ></el-option>
                      <el-option
                        label="已完工验收"
                        value="已完工验收"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单状态">
                    <el-select v-model="formInline1.orderStatus" size="mini">
                      <el-option label="正常" value="正常"></el-option>
                      <el-option label="关闭" value="关闭"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="项目公司">
                    <el-input
                      v-model="formInline1.projectCompanyName"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址">
                    <el-input
                      v-model="formInline1.address"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="销售姓名"
                    ><el-input
                      v-model="formInline1.sellName"
                      size="mini"
                    ></el-input
                  ></el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="安装方式">
                    <el-select v-model="formInline1.installType">
                      <el-option label="阳光房" value="阳光房"></el-option>
                      <el-option label="庭院" value="庭院"></el-option>
                      <el-option label="南北坡" value="南北坡"></el-option>
                      <el-option label="常规阵列" value="常规阵列"></el-option>
                      <el-option label="公共资源" value="公共资源"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="关联订单号">
                    <el-input
                      v-model="formInline1.orderCode"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="备注">
                    <el-input
                      v-model="formInline1.remark"
                      type="textarea"
                      placeholder="请输入内容"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="addUser-bottom positionr">
              <span class="span"></span>
              <p class="title">施工班组</p>
              <el-row class="flexrow">
                <el-col :span="8">
                  <el-form-item label="班组名称"
                    ><el-input
                      v-model="formInline1.constructionName"
                      class="small-height-input"
                      size="mini"
                    ></el-input
                  ></el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系人"
                    ><el-input
                      v-model="formInline1.constructionLinkman"
                      class="small-height-input"
                      size="mini"
                    ></el-input
                  ></el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="手机号" required>
                    <el-input
                      v-model="formInline1.constructionPhone"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="备注">
                    <el-input
                      v-model="formInline1.constructionRemark"
                      type="textarea"
                      placeholder="请输入内容"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
        <div v-if="customerType === '2'">
          <el-form
            :model="formInline2"
            ref="companyForm"
            label-width="100px"
            :rules="customerRules"
          >
            <div class="company">
              <div class="company-top">
                <span class="span"></span>
                <p class="title">公司信息</p>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="公司名称">
                      <el-input
                        v-model="formInline2.companyName"
                        placeholder="请输入公司名称"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="法人">
                      <el-input
                        v-model="formInline2.legalName"
                        placeholder="请输入法人姓名"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- line2 -->
                  <el-col :span="8">
                    <el-form-item label="法人手机">
                      <el-input
                        v-model="formInline2.legalMobile"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="客户类型">
                      <el-select
                        v-model="formInline2.life"
                        class="small-height-input"
                        size="mini"
                      >
                        <el-option label="新客户" :value="1"></el-option>
                        <el-option label="潜在客户" :value="2"></el-option>
                        <el-option label="意向客户" :value="3"></el-option>
                        <el-option label="商机客户" :value="4"></el-option>
                        <el-option label="流失客户" :value="5"></el-option>
                        <el-option
                          label="成交客户"
                          :value="6"
                        ></el-option> </el-select
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="行业类型">
                      <el-select
                        v-model="formInline2.industryType"
                        class="small-height-input"
                        size="mini"
                      >
                        <el-option label="行业一" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="证件类型">
                      <el-select
                        v-model="formInline2.legalCertificateType"
                        class="small-height-input"
                        size="mini"
                      >
                        <el-option label="证件类型一" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="证件号">
                      <el-input
                        v-model="formInline2.certificateNo"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="所在城市">
                      <el-input
                        v-model="formInline2.legalCityName"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="详细地址">
                      <el-input
                        v-model="formInline2.address"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="人员规模">
                      <el-input
                        v-model="formInline2.companyScale"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="公司网址">
                      <el-input
                        v-model="formInline2.companyGw"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建人">
                      <el-input
                        v-model="formInline2.createName"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建时间">
                      <el-date-picker
                        style="width: 194px"
                        v-model="formInline2.createDate"
                        value-format="yyyy-MM-dd"
                        class="small-height-input"
                        type="date"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="备注">
                      <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="formInline2.companyRemark"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- <el-row>
                  <el-col :span="8">
                    <el-form-item label="法人手机" label-width="100px" prop="legalMobile" required>
                      <el-input v-model="formInline2.legalMobile" placeholder="请输入"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="客户类型" label-width="100px" prop="life" required>
                      <el-select v-model="formInline2.life" @change="changeStatus" placeholder="请选择" clearable>
                        <el-option label="新客户" value="1"></el-option>
                        <el-option label="潜在客户" value="2"></el-option>
                        <el-option label="意向客户" value="3"></el-option>
                        <el-option label="商机客户" value="4"></el-option>
                        <el-option label="流失客户" value="5"></el-option>
                        <el-option label="成交客户" value="6"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="行业类型" label-width="100px" prop="industryType">
                      <el-select v-model="formInline2.industryType" placeholder="请选择">
                        <el-option v-for="item in tab" :key="item.region" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="证件类型" label-width="100px" prop="legalCertificateType">
                    <el-select v-model="formInline2.legalCertificateType" placeholder="请选择">
                      <el-option v-for="item in tab" :key="item.region" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-col :span="8">
                    <el-form-item label="证件号" label-width="100px" prop="certificateNo">
                      <el-input v-model="formInline2.certificateNo" placeholder="请输入"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="8">
                    <el-form-item label="所在城市" label-width="100px" prop="cityName">
                      <el-select v-model="formInline2.cityName" placeholder="请选择">
                        <el-option v-for="item in tab" :key="item.region" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="详细地址" label-width="100px" prop="companyAddress">
                        <el-input v-model="formInline2.companyAddress" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="客户来源" label-width="100px" prop="source">
                        <el-input v-model="formInline2.source" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="人员规模" label-width="100px" prop="companyScale">
                        <el-input v-model="formInline2.companyScale" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="公司网址" label-width="100px" prop="companyGw">
                        <el-input v-model="formInline2.companyGw" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="创建人" label-width="100px" prop="createName">
                        <el-input v-model="formInline2.createName" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="创建时间" label-width="100px" prop="createDate">
                        <el-input v-model="formInline2.createDate" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="备注" prop="companyRemark">
                    <el-input type="textarea" v-model="formInline2.companyRemark"></el-input>
                  </el-form-item>
                </el-row> -->
              </div>
              <div class="company-bottom">
                <span class="span"></span>
                <p class="title">施工班组</p>
                <el-row class="flexrow">
                  <el-col :span="8">
                    <el-form-item label="班组名称"
                      ><el-input
                        v-model="formInline2.constructionName"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="联系人"
                      ><el-input
                        v-model="formInline2.constructionLinkman"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="手机号" required>
                      <el-input
                        v-model="formInline2.constructionPhone"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="备注">
                      <el-input
                        v-model="formInline2.constructionRemark"
                        type="textarea"
                        placeholder="请输入内容"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form>
        </div>
        <div v-if="customerType === '3'">
          <el-form
            :model="formInline2"
            ref="companyForm"
            label-width="100px"
            :rules="customerRules"
          >
            <div class="company">
              <div class="company-top">
                <span class="span"></span>
                <p class="title">公共屋顶信息</p>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="公司名称">
                      <el-input
                        v-model="formInline3.companyName"
                        placeholder="请输入公司名称"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="法人">
                      <el-input
                        v-model="formInline3.legalName"
                        placeholder="请输入法人姓名"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- line2 -->
                  <el-col :span="8">
                    <el-form-item label="法人手机">
                      <el-input
                        v-model="formInline3.legalMobile"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="客户类型">
                      <el-select
                        v-model="formInline3.life"
                        class="small-height-input"
                        size="mini"
                      >
                        <el-option label="新客户" :value="1"></el-option>
                        <el-option label="潜在客户" :value="2"></el-option>
                        <el-option label="意向客户" :value="3"></el-option>
                        <el-option label="商机客户" :value="4"></el-option>
                        <el-option label="流失客户" :value="5"></el-option>
                        <el-option
                          label="成交客户"
                          :value="6"
                        ></el-option> </el-select
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="行业类型">
                      <el-input
                        v-model="formInline3.industryType"
                        class="small-height-input"
                        size="mini"
                      >
                        <!-- <el-option label="行业一" :value="1"></el-option> -->
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="证件类型">
                      <el-input
                        v-model="formInline3.legalCertificateType"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="证件号">
                      <el-input
                        v-model="formInline3.certificateNo"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="所在城市">
                      <el-input
                        v-model="formInline3.legalCityName"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="详细地址">
                      <el-input
                        v-model="formInline3.address"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="人员规模">
                      <el-input
                        v-model="formInline3.companyScale"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="公司网址">
                      <el-input
                        v-model="formInline3.companyGw"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建人">
                      <el-input
                        v-model="formInline3.createName"
                        class="small-height-input"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建时间">
                      <el-date-picker
                        style="width: 194px"
                        v-model="formInline3.createDate"
                        value-format="yyyy-MM-dd"
                        class="small-height-input"
                        type="date"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="备注">
                      <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="formInline3.companyRemark"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- <el-row>
                  <el-col :span="8">
                    <el-form-item label="法人手机" label-width="100px" prop="legalMobile" required>
                      <el-input v-model="formInline2.legalMobile" placeholder="请输入"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="客户类型" label-width="100px" prop="life" required>
                      <el-select v-model="formInline2.life" @change="changeStatus" placeholder="请选择" clearable>
                        <el-option label="新客户" value="1"></el-option>
                        <el-option label="潜在客户" value="2"></el-option>
                        <el-option label="意向客户" value="3"></el-option>
                        <el-option label="商机客户" value="4"></el-option>
                        <el-option label="流失客户" value="5"></el-option>
                        <el-option label="成交客户" value="6"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="行业类型" label-width="100px" prop="industryType">
                      <el-select v-model="formInline2.industryType" placeholder="请选择">
                        <el-option v-for="item in tab" :key="item.region" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="证件类型" label-width="100px" prop="legalCertificateType">
                    <el-select v-model="formInline2.legalCertificateType" placeholder="请选择">
                      <el-option v-for="item in tab" :key="item.region" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-col :span="8">
                    <el-form-item label="证件号" label-width="100px" prop="certificateNo">
                      <el-input v-model="formInline2.certificateNo" placeholder="请输入"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="8">
                    <el-form-item label="所在城市" label-width="100px" prop="cityName">
                      <el-select v-model="formInline2.cityName" placeholder="请选择">
                        <el-option v-for="item in tab" :key="item.region" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="详细地址" label-width="100px" prop="companyAddress">
                        <el-input v-model="formInline2.companyAddress" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="客户来源" label-width="100px" prop="source">
                        <el-input v-model="formInline2.source" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="人员规模" label-width="100px" prop="companyScale">
                        <el-input v-model="formInline2.companyScale" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="公司网址" label-width="100px" prop="companyGw">
                        <el-input v-model="formInline2.companyGw" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="创建人" label-width="100px" prop="createName">
                        <el-input v-model="formInline2.createName" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="创建时间" label-width="100px" prop="createDate">
                        <el-input v-model="formInline2.createDate" placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="备注" prop="companyRemark">
                    <el-input type="textarea" v-model="formInline2.companyRemark"></el-input>
                  </el-form-item>
                </el-row> -->
              </div>
              <div class="company-bottom">
                <span class="span"></span>
                <p class="title">施工班组</p>
                <el-row class="flexrow">
                  <el-col :span="8">
                    <el-form-item label="班组名称"
                      ><el-input
                        v-model="formInline3.constructionName"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="联系人"
                      ><el-input
                        v-model="formInline3.constructionLinkman"
                        class="small-height-input"
                        size="mini"
                      ></el-input
                    ></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="手机号" required>
                      <el-input
                        v-model="formInline3.constructionPhone"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="备注">
                      <el-input
                        v-model="formInline3.constructionRemark"
                        type="textarea"
                        placeholder="请输入内容"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button
        style="width: 80px; height: 35px; font-size: 14px; margin-right: 20px"
        type="primary"
        @click="submitHandle('companyForm')"
        >保 存</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFirstObj: {},
      companyOptions: [],

      outerVisible: true,
      input: "",
      options: [
        {
          value: "选项1",
          label: "男",
        },
        {
          value: "选项2",
          label: "女",
        },
      ],
      tab: [],
      //自然人表单
      formInline1: {
        //个人
        customerName: "", //姓名
        mobile: "",
        certificateNo: "",
        orderSchedule: "",
        orderStatus: "",
        projectCompanyName: "",
        address: "",
        sellName: "",
        remark: "",
        constructionName: "",
        constructionLinkman: "",
        constructionPhone: "",
        constructionRemark: "",
      },
      //公司表单
      formInline2: {
        constructionName: "",
        constructionLinkman: "",
        constructionPhone: "",
        constructionRemark: "",
        //公司信息
        companyName: "",
        companyCode: "",
        legalName: "",
        life: "",
        industryType: "",
        legalCertificateType: "",
        legalCityName: "",
        certificateNo: "",
        legalCityName: "",
        address: "",
        source: 1,
        companyScale: "",
        companyGw: "",
        createName: "",
        createDate: "",
        companyRemark: "",
      },
      //公共屋顶表单
      formInline3: {
        constructionName: "",
        constructionLinkman: "",
        constructionPhone: "",
        constructionRemark: "",
        //公司信息
        companyName: "",
        companyCode: "",
        legalName: "",
        life: "",
        industryType: "",
        legalCertificateType: "",
        legalCityName: "",
        certificateNo: "",
        legalCityName: "",
        address: "",
        source: 1,
        companyScale: "",
        companyGw: "",
        createName: "",
        createDate: "",
        companyRemark: "",
      },
      customerType: "1",
      textarea: "",
      desc: [{ required: true, message: "", trigger: "blur" }],
      manageRules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确手机号",
            trigger: "blur",
          },
        ],
        certificateNo: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确身份证号",
            trigger: "blur",
          },
        ],
      },
      customerRules: {
        legalName: [
          { required: true, message: "请输入法人名称", trigger: "blur" },
        ],
        life: [{ required: true, message: "请选择客户类型", trigger: "blur" }],
      },
      companyRules: {
        companyName: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
        legalName: [
          { required: true, message: "请输入法人名称", trigger: "blur" },
        ],
        legalMobile: [
          {
            required: true,
            message: "请输入正确手机号",
            trigger: "blur",
            pattern: /^1[3-9]\d{9}$/,
          },
        ],
        life: [{ required: true, message: "请选择客户类型", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCompanyList();
  },
  methods: {
    async getCompanyList() {
      // /houseOrgan/getCompanyList

      let res = await this.$http.post("/houseOrgan/getCompanyList", {
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode,
      });
      // console.log(localStorage.getItem("userInfo").userInfo,964);
      // let res = await this.$http.post("userOrgan/queryList", {
      //   userCode: JSON.parse(localStorage.getItem("userInfo")).userInfo.userCode
      // });
      if (res.data.code == 200) {
        this.companyOptions = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    setForm1Sex() {
      let n = Number(this.formInline1.certificateNo);
      if (!isNaN(n)) {
        // 是数字
        if (String(n).slice(-2, -1) % 2 == 1) {
          // 奇数
          this.formInline1.sex = 1;
        } else if (String(n).slice(-2, -1) % 2 == 0) {
          // 偶数
          this.formInline1.sex = 2;
        }
      }
    },
    async submitHandle() {
      if (this.customerType === "1") {
        this.$refs.customerForm.validate((ok) => {
          if (ok) {
            this.add();
          }
        });
      } else {
        this.add();
      }
    },

    async add() {
      let arr = ["", this.formInline1, this.formInline2, this.formInline3];
      let data = JSON.parse(JSON.stringify(arr[this.customerType]));
      if (!this.isFirstObj) {
        this.$message.info("请选择归属公司");
        return;
      }
      data.agentCode = this.isFirstObj.organCode;
      data.agentName = this.isFirstObj.organName;
      data.customertype = this.customerType;
      data.createName = JSON.parse(
        localStorage.getItem("userInfo")
      ).userInfo.name;
      data.createCode = JSON.parse(
        localStorage.getItem("userInfo")
      ).userInfo.userCode;
      console.log(data);
      let res = await this.$http.post("houseCustomer/addCustomer", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        console.log("kkkkkkkkkkkkk");
        this.$router.push({ path: "/userManage/dataCompletion" });
        this.resetForms();
      } else {
        this.$message.error(res.data.message);
      }
    },

    changeName(val) {
      this.customerType = val;
    },
    // submitHandle(companyForm) {
    //   // 自然人
    //   let data = [];
    //   if (this.customerType == "1") {
    //     this.formInline1.customerType = this.customerType;
    //     data = this.formInline1;
    //   } else if (this.customerType == "2") {
    //     this.formInline2.customerType = this.customerType;
    //     data = this.formInline2;
    //   } else {
    //     this.formInline3.customerType = this.customerType;
    //     data = this.formInline3;
    //   }
    //   this.$refs[companyForm].validate((valid) => {
    //     if (valid) {
    //       this.$http.post("/houseCustomer/addCustomer", data).then((res) => {
    //         if (res.code == 200) {
    //           this.$message.success("添加成功");
    //           this.outerVisible = false;
    //           this.$router.push("/userManage/newCustomer");
    //         } else {
    //           console.log(res.data);
    //         }
    //       });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
    changeStatus(val) {
      this.formInline.status = val;
    },
  },
};
</script>

<style lang="less">
.addUserManage {
  position: relative;
  padding-top: 7px;
  min-height: 100vh-12px;
  background-color: #ffffff;

  .btn {
    border: 1px solid #dcdfe6;
    background-color: #fff;
    width: 80px;
    height: 30px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
  }

  .active {
    background-color: #02a7f0;
    color: white;
  }

  .company {
    .title {
      font-size: 16px;
      font-weight: 800;
    }

    .company-top {
      position: relative;

      .span {
        width: 6px;
        height: 22px;
        background-color: #02a7f0;
        position: absolute;
        top: 0px;
        left: -10px;
      }
    }

    .company-bottom {
      position: relative;

      .span {
        width: 6px;
        height: 22px;
        background-color: #02a7f0;
        position: absolute;
        top: 0px;
        left: -10px;
      }
    }
  }

  .roof {
    position: relative;

    .title {
      font-size: 16px;
      font-weight: 800;
    }

    .span {
      width: 6px;
      height: 22px;
      background-color: #02a7f0;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }

  .el-dialog__title {
    font-weight: 800;
    font-size: 16px;
  }

  .all {
    width: 1000px;
    margin-top: -20px;
    margin-left: 50%;
    margin-bottom: 60px;
    transform: translateX(-50%);
    padding-left: 40px;
    padding-top: 20px;

    .el-dialog__title {
      font-weight: 800 !important;
      font-size: 16px !important;
    }

    // .el-textarea__inner {
    //   min-width: 1165px !important;
    //   min-height: 100px !important;
    // }

    .span {
      width: 6px;
      height: 22px;
      background-color: #02a7f0;
      position: absolute;
      top: 0px;
      left: -10px;
    }

    .positionr {
      position: relative;
    }

    .title {
      font-size: 16px;
      font-weight: 800;
    }
  }

  .footer {
    background-color: #fff;
    text-align: center;
    width: 100%;
    height: 60px;
    line-height: 60px;
    position: fixed;
    bottom: 0px;
    // left: 50%;
    // transform: translateX(50%);
  }
}
</style>
